<template>
  <div>
    <v-dialog
      v-model="showDialog"
      max-width="771"
      rounded="lg"
      persistent
      attach=".tyre-offer"
      hide-overlay
    >
      <v-card class="base-dialog offer-form-dialog">
        <v-card-title
          class="headline"
        >
          <h2
            class="title-text"
          >
            {{ $t(title) }}
          </h2>
          <small class="note-text">{{ note }}</small>
        </v-card-title>
        <v-card-text>
          <div class="settings-form">
            <table style="width: 100%">
              <colgroup>
                <col
                  span="1"
                  style="width: 40%;"
                >
                <col
                  span="1"
                  style="width: 60%;"
                >
              </colgroup>

              <tbody>
                <tr>
                  <td />
                  <td>
                    <p
                      v-if="errorMessage"
                      class="error-message"
                      v-html="errorMessage"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.consultant ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.consultant') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.consultant"
                      class="error-message"
                      v-html="inputErrorMessage.consultant"
                    />
                    <v-text-field
                      v-model="editTyreOfferData.consultant"
                      solo
                      hide-details
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.name ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.name') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.name"
                      class="error-message"
                      v-html="inputErrorMessage.name"
                    />
                    <v-text-field
                      v-model="editTyreOfferData.name"
                      solo
                      hide-details
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.company ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.company') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.company"
                      class="error-message"
                      v-html="inputErrorMessage.company"
                    />
                    <v-text-field
                      v-model="editTyreOfferData.company"
                      solo
                      hide-details
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.street ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.street') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.street"
                      class="error-message"
                      v-html="inputErrorMessage.street"
                    />
                    <v-text-field
                      v-model="editTyreOfferData.street"
                      solo
                      hide-details
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.zip ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.zip') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.zip"
                      class="error-message"
                      v-html="inputErrorMessage.zip"
                    />
                    <v-text-field
                      v-model="editTyreOfferData.zip"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.city ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.city') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.city"
                      class="error-message"
                      v-html="inputErrorMessage.city"
                    />
                    <v-text-field
                      v-model="editTyreOfferData.city"
                      solo
                      hide-details
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      :class="[
                        inputError.licensePlate ?
                          'error-message' : ''
                      ]"
                    >
                      {{ $t('langkey.licensePlate') }}
                    </span>
                  </td>
                  <td>
                    <p
                      v-if="inputError.licensePlate"
                      class="error-message"
                      v-html="inputErrorMessage.licensePlate"
                    />
                    <v-text-field
                      v-model="editTyreOfferData.licensePlate"
                      solo
                      hide-details
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      {{ $t('langkey.comment') }}
                    </span>
                  </td>
                  <td>
                    <v-text-field
                      v-model="editTyreOfferData.comment"
                      solo
                      hide-details
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      {{ $t('langkey.date') }}
                    </span>
                  </td>
                  <td>
                    {{ getDate }}
                  </td>
                </tr>
              </tbody>
            </table>

            <table
              class="calculation"
              style="width: 100%"
            >
              <colgroup>
                <col
                  span="1"
                  style="width: 40%;"
                >
                <col
                  span="1"
                  style="width: 20%;"
                >
                <col
                  span="1"
                  style="width: 20%;"
                >
                <col
                  span="1"
                  style="width: 20%;"
                >
              </colgroup>

              <thead>
                <tr>
                  <th>{{ $t('langkey.name') }}</th>
                  <th>{{ $t('langkey.price') }}</th>
                  <th>{{ $t('langkey.quantity') }}</th>
                  <th>{{ $t('langkey.total') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in calculationItems"
                  :key="`${item.value}${index}`"
                >
                  <td>
                    <span v-if="item.value === 'tyre'">
                      {{ selectedTyreData.description }}
                    </span>
                    <v-text-field
                      v-else-if="item.isOptional"
                      solo
                      hide-details
                      @input="addOptionalCalculationItem($event, item.value)"
                    />
                    <span v-else>
                      {{ $t(item.label) }}
                    </span>
                  </td>
                  <td>
                    <v-text-field
                      v-model.number="editTyreOfferData.calculation[item.value].price"
                      solo
                      hide-details
                      type="number"
                    >
                      <template #append>
                        <i class="append-icon">
                          €
                        </i>
                      </template>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model.number="editTyreOfferData.calculation[item.value].quantity"
                      solo
                      hide-details
                      type="number"
                    />
                  </td>
                  <td>
                    <v-text-field
                      :value="getTotalPrice(editTyreOfferData.calculation[item.value])"
                      solo
                      readonly
                      hide-details
                      type="number"
                    >
                      <template #append>
                        <i class="append-icon">
                          €
                        </i>
                      </template>
                    </v-text-field>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="calculation-summary">
              <p class="label">
                {{ $t('langkey.total_price_including_vat') }}
              </p>
              <p>
                {{ totalWithVat | price }}
              </p>
              <p class="label">
                {{ $t('langkey.vat') }}
              </p>
              <p>
                {{ vat | price }}
              </p>
              <p class="label">
                {{ $t('langkey.total_price') }}
              </p>
              <p>
                {{ total | price }}
              </p>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="prevRoute.name"
            text
            @click="onBack"
          >
            {{ $t('langkey.back') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="accent"
            class="icon"
            @click="onSave"
          >
            {{ $t('langkey.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay
      v-if="showDialog"
      absolute
    />
  </div>
</template>

<style lang="scss">
@import "@/styles/components/offer-form-dialog.scss";
</style>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    note: {
      type: String,
      default: '',
    },
    selectedTyreData: {
      type: Object,
      default: () => {},
    },
    prevRoute: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showDialog: false,
      errorMessage: '',
      calculationItems: [
        {
          label: '',
          value: 'tyre',
        },
        {
          label: 'langkey.pcr_alloy_mounting',
          value: 'alloyMountCost',
        },
        {
          label: 'langkey.dealer-calculation-tpms-sensor',
          value: 'tpmsSensor',
        },
        {
          label: 'langkey.tpms_programming',
          value: 'tpmsProgramming',
        },
        {
          label: 'langkey.valve_rubber',
          value: 'valveRubber',
        },
        {
          label: 'langkey.valve_metal',
          value: 'valveMetal',
        },
        {
          label: 'langkey.heave',
          value: 'heave',
        },
        {
          label: 'langkey.adhesive_weight_flat_rate',
          value: 'adhesiveWeightFlatRate',
        },
        {
          label: 'langkey.impact_weight_flat_rate',
          value: 'impactWeightFlatRate',
        },
        {
          label: 'langkey.wheel_mount',
          value: 'wheelMount',
        },
        {
          label: 'langkey.wheel_wash',
          value: 'wheelWash',
        },
        {
          label: 'langkey.warehousing',
          value: 'warehousing',
        },
        {
          label: 'langkey.freight_charges',
          value: 'freightCharges',
        },
        {
          label: 'langkey.tyre_disposal',
          value: 'tyreDisposal',
        },
        {
          label: 'langkey.wheel_alignment',
          value: 'wheelAlignment',
        },
        {
          label: '',
          value: 'optional1',
          isOptional: true,
        },
        {
          label: '',
          value: 'optional2',
          isOptional: true,
        },
        {
          label: '',
          value: 'optional3',
          isOptional: true,
        },
      ],
      editTyreOfferData: {
        consultant: '',
        name: '',
        company: '',
        street: '',
        zip: '',
        city: '',
        licensePlate: '',
        comment: '',
        calculation: {
          tyre: {
            price: 0,
            quantity: 4,
          },
          alloyMountCost: {
            price: 0,
            quantity: 0,
          },
          tpmsSensor: {
            price: 0,
            quantity: 0,
          },
          tpmsProgramming: {
            price: 0,
            quantity: 0,
          },
          valveRubber: {
            price: 0,
            quantity: 0,
          },
          valveMetal: {
            price: 0,
            quantity: 0,
          },
          heave: {
            price: 0,
            quantity: 0,
          },
          adhesiveWeightFlatRate: {
            price: 0,
            quantity: 0,
          },
          impactWeightFlatRate: {
            price: 0,
            quantity: 0,
          },
          wheelMount: {
            price: 0,
            quantity: 0,
          },
          wheelWash: {
            price: 0,
            quantity: 0,
          },
          warehousing: {
            price: 0,
            quantity: 0,
          },
          freightCharges: {
            price: 0,
            quantity: 0,
          },
          tyreDisposal: {
            price: 0,
            quantity: 0,
          },
          wheelAlignment: {
            price: 0,
            quantity: 0,
          },
          optional1: {
            price: 0,
            quantity: 0,
          },
          optional2: {
            price: 0,
            quantity: 0,
          },
          optional3: {
            price: 0,
            quantity: 0,
          },
        },
      },
      inputError: {
        consultant: false,
        name: false,
        company: false,
        street: false,
        zip: false,
        city: false,
        licensePlate: false,
        tyre: false,
        alloyMountCost: false,
        tpmsSensor: false,
        tpmsProgramming: false,
        valveRubber: false,
        valveMetal: false,
        heave: false,
        adhesiveWeightFlatRate: false,
        impactWeightFlatRate: false,
        wheelMount: false,
        wheelWash: false,
        warehousing: false,
        freightCharges: false,
        tyreDisposal: false,
        wheelAlignment: false,
        option1: false,
        option2: false,
        option3: false,
      },
      inputErrorMessage: {
        consultant: '',
        name: '',
        company: '',
        street: '',
        zip: '',
        city: '',
        licensePlate: '',
        tyre: '',
        alloyMountCost: '',
        tpmsSensor: '',
        tpmsProgramming: '',
        valveRubber: '',
        valveMetal: '',
        heave: '',
        adhesiveWeightFlatRate: '',
        impactWeightFlatRate: '',
        wheelMount: '',
        wheelWash: '',
        warehousing: '',
        freightCharges: '',
        tyreDisposal: '',
        wheelAlignment: '',
        option1: '',
        option2: '',
        option3: '',
      },
      getDate: '',
    };
  },
  computed: {
    ...mapState('dealerCalculation', {
      tyreOfferData: 'offerToolSettings',
    }),
    total() {
      let result = 0;
      const calculationOfferData = Object.values(this.editTyreOfferData.calculation);
      calculationOfferData.forEach((item) => {
        result += item.price * item.quantity;
      });
      return result;
    },
    vatValue() {
      return this.tyreOfferData.tax;
    },
    vat() {
      return this.total * this.vatValue / 100;
    },
    totalWithVat() {
      return this.total + this.vat;
    },
    tyreOfferPriceData() {
      return {
        totalWithVat: this.totalWithVat,
        vat: this.vat,
        total: this.total,
      };
    },
  },
  watch: {
    selectedTyreData(val) {
      this.editTyreOfferData.calculation.tyre.price = val.prices.bestPrice.retailPrice;
    },
  },
  mounted() {
    this.getDate = dayjs().tz('Europe/Berlin').format('LLL');
  },
  beforeDestroy() {
    this.$store.dispatch('dialog/setLoadingDialog', {
      status: false,
    });
  },
  methods: {
    show() {
      Object.keys(this.editTyreOfferData.calculation).forEach((key) => {
        if (this.tyreOfferData[key]) {
          this.editTyreOfferData.calculation[key].price = this.tyreOfferData[key];
        }
      });
      this.showDialog = true;
    },
    hide() {
      this.showDialog = false;
    },
    async onSave() {
      this.$emit('on-save', {
        editTyreOfferData: this.editTyreOfferData,
        tyreOfferPriceData: this.tyreOfferPriceData,
        calculationItems: this.calculationItems,
      });
    },
    getTotalPrice(calculationItem) {
      return calculationItem.price * calculationItem.quantity;
    },
    addOptionalCalculationItem(e, value) {
      const optionalItemPosition = this.calculationItems.findIndex((item) => item.value === value);
      this.calculationItems[optionalItemPosition].label = e;
    },
    onBack() {
      this.$router.push(this.$i18nRoute({
        name: this.prevRoute.name,
        query: this.prevRoute.query,
      }));
    },
  },
};
</script>
