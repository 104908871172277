<template>
  <div>
    <v-dialog
      v-model="showDialog"
      max-width="771"
      rounded="lg"
      persistent
      attach=".tyre-offer"
      hide-overlay
    >
      <v-card class="base-dialog offer-form-dialog">
        <v-card-title
          class="headline"
        >
          <h2
            class="title-text"
          >
            {{ $t(title) }}
          </h2>
          <small class="note-text">{{ note }}</small>
        </v-card-title>
        <v-card-text>
          <div class="settings-form">
            <table style="width: 100%">
              <colgroup>
                <col
                  span="1"
                  style="width: 40%;"
                >
                <col
                  span="1"
                  style="width: 60%;"
                >
              </colgroup>

              <tbody>
                <tr>
                  <td>
                    <span>
                      {{ $t('langkey.consultant') }}
                    </span>
                  </td>
                  <td>
                    <span>{{ confirmTyreOfferData.consultant }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      {{ $t('langkey.name') }}
                    </span>
                  </td>
                  <td>
                    <span>{{ confirmTyreOfferData.name }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      {{ $t('langkey.company') }}
                    </span>
                  </td>
                  <td>
                    <span>{{ confirmTyreOfferData.company }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      {{ $t('langkey.street') }}
                    </span>
                  </td>
                  <td>
                    <span>{{ confirmTyreOfferData.street }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      {{ $t('langkey.zip') }}
                    </span>
                  </td>
                  <td>
                    <span>{{ confirmTyreOfferData.zip }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      {{ $t('langkey.city') }}
                    </span>
                  </td>
                  <td>
                    <span>{{ confirmTyreOfferData.city }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      {{ $t('langkey.licensePlate') }}
                    </span>
                  </td>
                  <td>
                    <span>{{ confirmTyreOfferData.licensePlate }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      {{ $t('langkey.comment') }}
                    </span>
                  </td>
                  <td>
                    <span>{{ confirmTyreOfferData.comment }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      {{ $t('langkey.date') }}
                    </span>
                  </td>
                  <td>
                    {{ getDate }}
                  </td>
                </tr>
              </tbody>
            </table>

            <table
              class="calculation"
              style="width: 100%"
            >
              <colgroup>
                <col
                  span="1"
                  style="width: 40%;"
                >
                <col
                  span="1"
                  style="width: 20%;"
                >
                <col
                  span="1"
                  style="width: 20%;"
                >
                <col
                  span="1"
                  style="width: 20%;"
                >
              </colgroup>

              <thead>
                <tr>
                  <th>{{ $t('langkey.name') }}</th>
                  <th>{{ $t('langkey.price') }}</th>
                  <th>{{ $t('langkey.quantity') }}</th>
                  <th>{{ $t('langkey.total') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in getConfirmCalculationItems"
                  :key="`${item.value}${index}`"
                >
                  <td>
                    <span v-if="item.value === 'tyre'">
                      {{ selectedTyreData.description }}
                    </span>
                    <span v-else-if="item.label.includes('langkey')">
                      {{ $t(item.label) }}
                    </span>
                    <span v-else>
                      {{ item.label }}
                    </span>
                  </td>
                  <td>
                    <span>{{ confirmTyreOfferData.calculation[item.value].price | price }}</span>
                  </td>
                  <td>
                    <span>{{ confirmTyreOfferData.calculation[item.value].quantity }}</span>
                  </td>
                  <td>
                    <span>{{ getTotalPrice(confirmTyreOfferData.calculation[item.value]) | price }}</span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="calculation-summary">
              <p class="label">
                {{ $t('langkey.total_price_including_vat') }}
              </p>
              <p>
                {{ confirmTyreOfferPriceData.totalWithVat | price }}
              </p>
              <p class="label">
                {{ $t('langkey.vat') }}
              </p>
              <p>
                {{ confirmTyreOfferPriceData.vat | price }}
              </p>
              <p class="label">
                {{ $t('langkey.total_price') }}
              </p>
              <p>
                {{ confirmTyreOfferPriceData.total | price }}
              </p>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="onBack"
          >
            {{ $t('langkey.back') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            class="icon"
            @click="onPrint"
          >
            {{ $t('langkey.print') }}
            <icon-pdf
              color="var(--theme-black)"
              bg-color="var(--theme-white)"
            />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay
      v-if="showDialog"
      absolute
    />

    <vue-html2pdf
      ref="html2Pdf"
      style="display: none;"
      :html-to-pdf-options="offerFormPdfOption"
      :enable-download="false"
      :preview-modal="false"
      @beforeDownload="beforeDownload($event)"
      @hasDownloaded="hasDownloaded($event)"
    >
      <template #pdf-content>
        <offer-form-pdf
          :title="title"
          :note="note"
          :tyre-offer-data="confirmTyreOfferData"
          :tyre-offer-price-data="confirmTyreOfferPriceData"
          :calculation-items="getConfirmCalculationItems"
          :tyre-description="selectedTyreData.description"
        />
      </template>
    </vue-html2pdf>
  </div>
</template>

<style lang="scss">
@import "@/styles/components/offer-form-dialog.scss";
</style>

<script>
import VueHtml2pdf from '@/components/pdf/VueHtml2pdf';
import OfferFormPdf from '@/components/pdf/OfferFormPdf';
import IconPdf from '@/components/icons/IconPdf';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
import _ from 'lodash';

export default {
  components: {
    VueHtml2pdf,
    OfferFormPdf,
    IconPdf,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    note: {
      type: String,
      default: '',
    },
    selectedTyreData: {
      type: Object,
      default: () => {},
    },
    confirmTyreOfferData: {
      type: Object,
      default: () => {},
    },
    confirmTyreOfferPriceData: {
      type: Object,
      default: () => {},
    },
    calculationItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showDialog: false,
      getDate: '',
      offerFormPdfOption: {
        margin: 0,

        image: {
          type: 'png',
          quality: 0.98,
        },

        html2canvas: {
          scale: 2,
          useCORS: true,
        },

        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'portrait',
        },
      },
    };
  },
  computed: {
    getConfirmCalculationItems() {
      if (!this.empty(this.confirmTyreOfferData)) {
        return this.calculationItems.filter((item) => {
          return this.confirmTyreOfferData.calculation[item.value].quantity !== 0;
        });
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.getDate = dayjs().tz('Europe/Berlin').format('LLL');
  },
  beforeDestroy() {
    this.$store.dispatch('dialog/setLoadingDialog', {
      status: false,
    });
  },
  methods: {
    empty: _.isEmpty,
    show() {
      this.showDialog = true;
    },
    hide() {
      this.showDialog = false;
    },
    onBack() {
      this.$emit('on-back');
    },
    async onPrint() {
      window.scrollTo(0, 0);
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });

      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 600);
    },
    getTotalPrice(calculationItem) {
      return calculationItem.price * calculationItem.quantity;
    },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        // Set note in the footer
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(8.4);
          pdf.text(
            this.note,
            0.4,
            pdf.internal.pageSize.getHeight() - 0.5,
            { maxWidth: pdf.internal.pageSize.getWidth() - 0.8 },
          );
        }
        window.open(pdf.output('bloburl'), '_blank');
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      });
    },
    hasDownloaded() {
      // Not triggered if enable-download and preview-modal are set to false
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: false,
      });
    },
  },
};
</script>
