<template>
  <section class="pdf-content">
    <div class="offer-header">
      <img
        class="offer-logo-img"
        :src="getOfferLogo"
      >
      <div class="user-address">
        <p>
          {{ userInformation.business.name }}<br>
          {{ userInformation.business.address }}<br>
          {{ userInformation.business.postcode }} {{ userInformation.business.city }}
        </p>
      </div>
    </div>
    <div class="pdf-content-wrapper">
      <table
        style="width: 100%"
        class="information"
      >
        <colgroup>
          <col
            span="1"
            style="width: 50%;"
          >
          <col
            span="1"
            style="width: 50%;"
          >
        </colgroup>

        <tbody>
          <tr>
            <td
              style="text-align: right"
              class="label"
            >
              <span>{{ $t('langkey.name') }}</span>
            </td>
            <td>
              <span>{{ tyreOfferData.name }}</span>
            </td>
          </tr>
          <tr>
            <td
              style="text-align: right"
              class="label"
            >
              <span>{{ $t('langkey.company') }}</span>
            </td>
            <td>
              <span>{{ tyreOfferData.company }}</span>
            </td>
          </tr>
          <tr>
            <td
              style="text-align: right"
              class="label"
            >
              <span>{{ $t('langkey.street') }}</span>
            </td>
            <td>
              <span>{{ tyreOfferData.street }}</span>
            </td>
          </tr>
          <tr>
            <td
              style="text-align: right"
              class="label"
            >
              <span>{{ $t('langkey.zip') }}</span>
            </td>
            <td>
              <span>{{ tyreOfferData.zip }}</span>
            </td>
          </tr>
          <tr>
            <td
              style="text-align: right"
              class="label"
            >
              <span>{{ $t('langkey.city') }}</span>
            </td>
            <td>
              <span>{{ tyreOfferData.city }}</span>
            </td>
          </tr>
          <tr>
            <td
              style="text-align: right"
              class="label"
            >
              <span>{{ $t('langkey.licensePlate') }}</span>
            </td>
            <td>
              <span>{{ tyreOfferData.licensePlate }}</span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="more-information">
        <p>
          <span class="label">{{ $t('langkey.date') }}:</span> {{ getDate }}
        </p>
        <p>
          <span class="label">{{ $t('langkey.consultant') }}:</span> {{ tyreOfferData.consultant }}
        </p>
      </div>

      <div class="offer-title">
        <h2
          class="title-text"
        >
          {{ $t(title) }}
        </h2>
        <p
          class="offer-pdf-note"
          v-html="$t('langkey.offer_pdf_note')"
        />
      </div>

      <table
        class="calculation"
        style="width: 100%"
        cellspacing="0"
      >
        <colgroup>
          <col
            span="1"
            style="width: 55%;"
          >
          <col
            span="1"
            style="width: 15%;"
          >
          <col
            span="1"
            style="width: 15%;"
          >
          <col
            span="1"
            style="width: 15%;"
          >
        </colgroup>

        <thead>
          <tr>
            <th>{{ $t('langkey.name') }}</th>
            <th>{{ $t('langkey.price') }}</th>
            <th>{{ $t('langkey.quantity') }}</th>
            <th>{{ $t('langkey.total') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in calculationItems"
            :key="`${item.value}${index}`"
          >
            <td>
              <span v-if="item.value === 'tyre'">
                {{ tyreDescription }}
              </span>
              <span v-else-if="item.label.includes('langkey')">
                {{ $t(item.label) }}
              </span>
              <span v-else>
                {{ item.label }}
              </span>
            </td>
            <td style="text-align: right">
              <span>{{ tyreOfferData.calculation[item.value].price | price }}</span>
            </td>
            <td style="text-align: right">
              <span>{{ tyreOfferData.calculation[item.value].quantity }}</span>
            </td>
            <td style="text-align: right">
              <span>{{ getTotalPrice(tyreOfferData.calculation[item.value]) | price }}</span>
            </td>
          </tr>
          <tr>
            <td
              colspan="3"
              style="text-align: right"
            >
              <span>{{ $t('langkey.total_price_including_vat') }}</span>
            </td>
            <td style="text-align: right">
              <span>{{ tyreOfferPriceData.totalWithVat | price }}</span>
            </td>
          </tr>
          <tr>
            <td
              colspan="3"
              style="text-align: right"
            >
              <span>{{ $t('langkey.vat') }}</span>
            </td>
            <td style="text-align: right">
              <span>{{ tyreOfferPriceData.vat | price }}</span>
            </td>
          </tr>
          <tr>
            <td
              colspan="3"
              style="text-align: right"
            >
              <span>{{ $t('langkey.total_price') }}</span>
            </td>
            <td style="text-align: right">
              <span>{{ tyreOfferPriceData.total | price }}</span>
            </td>
          </tr>
          <tr style="text-align: right">
            <td colspan="4">
              <span v-html="$t('langkey.all_prices_include_tax', { tax: offerToolSettings.tax })" />
            </td>
          </tr>
        </tbody>
      </table>

      <p>{{ offerToolSettings.offerClosingText }}</p>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    note: {
      type: String,
      default: '',
    },
    tyreOfferData: {
      type: Object,
      default: () => {},
    },
    tyreOfferPriceData: {
      type: Object,
      default: () => {},
    },
    calculationItems: {
      type: Array,
      default: () => [],
    },
    tyreDescription: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      getDate: '',
    };
  },
  computed: {
    ...mapState('user', {
      userInformation: 'userInformation',
    }),
    ...mapState('dealerCalculation', {
      offerToolSettings: 'offerToolSettings',
    }),
    getOfferLogo() {
      return this.offerToolSettings.offerLogo;
    },
  },
  mounted() {
    this.getDate = dayjs().tz('Europe/Berlin').format('DD.MM.YYYY HH:mm:ss');
  },
  methods: {
    getTotalPrice(calculationItem) {
      return calculationItem.price * calculationItem.quantity;
    },
  },
};
</script>

<style lang="scss">
.pdf-content {
  padding: 40px;
  .offer-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;

    .offer-logo-img {
      height: 45px;
      object-fit: contain;
    }
  }

  .pdf-content-wrapper {
    .offer-title {
      .offer-pdf-note {
        margin-top: 4px;
      }
    }

    table {
      width: 100%;
      margin: 0 auto;
      border-collapse: collapse;
      th {
        font-family: "Overpass-Bold", sans-serif;
        font-weight: 700;
        text-align: center;
      }
      &.information {
        td {
          padding: 0 8px;
          &.label {
            font-family: "Overpass-Bold", sans-serif;
            font-weight: 700;
          }
        }
      }
      &.calculation {
        margin-top: 16px;
        margin-bottom: 16px;
        border: 1px solid black;
        th, td {
          border: 1px solid black;
          padding: 0 2px;
        }
      }
    }

    .more-information {
      text-align: right;
      width: 100%;
      margin-top: 8px;
      margin-bottom: 16px;
      p {
        span.label {
          font-family: "Overpass-Bold", sans-serif;
          font-weight: 700;
        }
      }
    }
  }
}
</style>