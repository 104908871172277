<template>
  <div :class="['tyre-offer', isOptIn && 'is-optin']">
    <offer-form-dialog
      ref="offerFormDialog"
      :title="$t('langkey.offer.title')"
      :note="$t('langkey.offer.note')"
      :selected-tyre-data="selectedTyreData"
      :prev-route="prevRoute"
      @on-save="onSave"
    />
    <offer-form-confirmation-dialog
      ref="offerFormConfirmationDialog"
      :title="`${$t('langkey.offer.title')}`"
      :note="$t('langkey.offer.note')"
      :selected-tyre-data="selectedTyreData"
      :confirm-tyre-offer-data="confirmTyreOfferData"
      :confirm-tyre-offer-price-data="confirmTyreOfferPriceData"
      :calculation-items="confirmCalculationItems"
      @on-back="onBack"
    />
  </div>
</template>

<style lang="scss">
@import '@/styles/pages/tyre-offer.scss';
</style>

<script>
import OfferFormDialog from '@/components/OfferFormDialog';
import OfferFormConfirmationDialog from '@/components/OfferFormConfirmationDialog';
import { mapState } from 'vuex';

export default {
  components: {
    OfferFormDialog,
    OfferFormConfirmationDialog,
  },
  data() {
    return {
      selectedTyreData: {},
      confirmTyreOfferData: {},
      confirmTyreOfferPriceData: {},
      confirmCalculationItems: [],
      prevRoute: {},
    };
  },
  beforeRouteEnter(to,from,next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  computed: {
    ...mapState('pricing', {
      isOptIn: 'isOptIn',
    }),
    // getTyreTag() {
    //   return this.$route.params.tyreTag;
    // },
    getTyreEans() {
      return this.$route.params.eans;
    },
  },
  created() {
    this.$store.dispatch('tyre/getTyresByTyreTag', {
      // tag: this.getTyreTag,
      eans: this.getTyreEans,
    })
      .then((res) => {
        this.selectedTyreData = res.data[0];
      })
      .catch(() => {
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: this.$t('langkey.tyre-is-missing'),
          message: this.$t('langkey.please-select-a-tyre'),
        })
          .then(() => {
            this.$router.push(this.$i18nRoute({
              name: 'tyre-car',
            }));
          });
      });
  },
  mounted() {
    this.$store.dispatch('dialog/setLoadingDialog', {
      status: true,
    });
    this.$store.dispatch('dealerCalculation/getOfferToolSettings')
      .then(() => {
        this.$refs.offerFormDialog.show();
      })
      .finally(() => {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      });
  },
  methods: {
    onSave({ editTyreOfferData, tyreOfferPriceData, calculationItems }) {
      this.$refs.offerFormDialog.hide();
      this.confirmTyreOfferData = editTyreOfferData;
      this.confirmTyreOfferPriceData = tyreOfferPriceData;
      this.confirmCalculationItems = calculationItems;
      this.$refs.offerFormConfirmationDialog.show();
    },
    onBack() {
      this.$refs.offerFormConfirmationDialog.hide();
      this.$refs.offerFormDialog.show();
    },
  },
};
</script>